import { isNull } from "lodash";

export const state = () => ({
  records: [],
  meta: {},
});

export const getters = {
  records(state) {
    return {
      data: state.records,
      meta: state.meta,
    };
  },


};

export const mutations = {
  SET_DATA(state, data) {
    state.records.push(...data.data);
    state.meta = data.meta;
  },
  RESET_DATA(state) {
    state.records = [];
    state.meta = {};
  },

};

export const actions = {
  fetch({ state, commit }, page) {
    return this.$axios.$get("/api/takings", { params: { page } }).then((resp) => {
      commit("SET_DATA", resp);
    });
  },
  clear({ commit }) {
    commit("RESET_DATA");
  },

};
