<template>
  <div>
    <div class="max-w-screen-sm m-auto bg-gray-50 h-app w-full">
      <!-- 內容 -->
      <div class="h-full scrollbar-hide overflow-y-scroll scrolling-touch">
        <nuxt />
      </div>
      <!-- 底部導覽 -->
      <div class="fixed bottom-0 pb-safe w-full inset-x-0">
        <BottomNavigationCard />
      </div>
    </div>

    <!-- 產品詳細 -->
    <van-popup
      position="bottom"
      v-model="show_popup"
      @closed="popupClose"
      @open="show_consent = true"
      class="z-[999999999999] max-w-screen-sm mx-auto inset-x-0 overflow-hidden"
    >
      <component :is="popup_el" v-bind="popup_data" />
    </van-popup>

    <!-- 購物車 -->
    <ShoppingCart v-if="showCart" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Specification from "@/components/product/Specification";
import Modal from "@luminexs/components/modal/Modal.vue";
import ShoppingCart from "@/components/cart/ShoppingCart";
import BankLegend from "@/components/takings/BankLegend.vue";
import BottomNavigationCard from "@/components/card/BottomNavigationCard.vue";

export default {
  components: {
    Specification,
    ShoppingCart,
    Modal,
    BankLegend,
    BottomNavigationCard,
  },
  head() {
    const info = this.$store.getters["app/info"];
    const imgs = this.$store.getters["app/imgs"];
    return {
      title: info.name,
      meta: [
        {
          hid: "description",
          name: "description",
          content: info.description,
        },
        {
          hid: "keywords",
          name: "keywords",
          content: info.keywords,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: info.name,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: info.description,
        },
        {
          hid: "og:image",
          property: "og:image",
          content: imgs.logo,
        },
        {
          hid: "og:url",
          property: "og:url",
          content: info.web,
        },
      ],
      link: [
        {
          rel: "icon",
          type: "image/x-icon",
          href: imgs.logo,
        },
      ],
    };
  },
  data() {
    return {
      show_popup: false,
      show_consent: false,
    };
  },
  methods: {
    ...mapActions({
      popupClose: "popup/close",
      fetchCategories: "app/fetchCategories",
      fetchIpAddress: "app/fetchIpAddress",
    }),
  },
  watch: {
    popup_enable(bool) {
      this.show_popup = bool;
    },
  },
  computed: {
    ...mapGetters({
      popup_enable: "popup/enable",
      popup_el: "popup/el",
      popup_data: "popup/data",
    }),
    showCart() {
      return ["index", "order"].includes(
        this.$route.name?.replace(`___${this.$i18n.locale}`, "") // replace ___ to empty string
      );
    },
  },
  created() {
    this.fetchCategories();
    this.fetchIpAddress();
  },
};
</script>
