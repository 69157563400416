<template>
  <div
    class="flex relative items-center text-white bg-primary flex-none h-14 font-medium"
  >
    <!-- 關閉按鈕 -->
    <button class="p-4" @click="$emit('close')">
      <XIcon />
    </button>

    <!-- title -->
    <div class="line-clamp-1 flex-grow text-center" v-text="title" />

    <!-- 分享按鈕 -->
    <button class="p-4" type="button" @click="$emit('share')" v-if="sharable">
      <ShareIcon />
    </button>
  </div>
</template>

<script>
import { XIcon, ShareIcon } from "@vue-hero-icons/outline";
export default {
  components: { XIcon, ShareIcon },
  props: {
    title: {
      type: String,
      required: true,
    },
    sharable: Boolean,
  },
};
</script>

<style lang="scss" scoped></style>
