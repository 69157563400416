export const state = () => ({
  el: null,
  enable: false,
  data: {}
})

export const getters = {
  el(state) {
    return state.el
  },

  enable(state) {
    return state.enable
  },

  data(state) {
    return state.data
  }
}

export const mutations = {
  SET_EL(state, el) {
    state.el = el
  },

  SET_ENABLE(state, bool) {
    state.enable = bool
  },

  SET_DATA(state, data) {
    state.data = data
  }
}

export const actions = {
  show({ commit }, {el, data}) {
    commit('SET_EL', el)
    commit('SET_DATA', data)
    commit('SET_ENABLE', true)
  },

  close({ commit }) {
    commit('SET_EL', null)
    commit('SET_DATA', {})
    commit('SET_ENABLE', false)
  }
}

