<template>
  <div class="flex py-3 px-4 space-x-4 flex-nowrap items-stretch">
    <div class="flex-grow overflow-x-scroll scrollbar-hide">
      <p class="text-sm">{{ item.name }}</p>
      <!-- 商品選項 -->
      <p class="text-gray-500 mb-2 text-xs">
        <span class="block" v-for="(option, index) in item.options" :key="index">{{
          option
        }}</span>
      </p>

      <!-- 商品卡密 -->
      <div v-if="variations && success" class="mt-3">
        <div
          v-for="(variation, index) in variations.split(', ')"
          :key="index"
          class="mb-5"
        >
          <CartListItemCardCode
            v-for="(code, subindex) in variation.split(' ')"
            :key="subindex"
            :code="code"
          />
        </div>

        <div class="text-xxs text-red-500 font-bold">＊直接點擊卡密即可複製</div>
      </div>
    </div>

    <div class="flex-none text-sm text-right">
      <!-- 商品價格 -->
      <p>{{ currency }}{{ item.price | number_format }}</p>
      <!-- 數量調整器 -->
      <QuantityPanel
        class="mt-2"
        :value="item.quantity"
        @input="update"
        :min="0"
        v-if="!view"
      />
      <!-- 顯示數量 -->
      <p v-else>x{{ item.quantity }}</p>
    </div>

    <!-- <div class="flex-shrink text-sm w-16 text-right">
        <span class="text-xxs">{{ currency }}</span
        >{{ item.price | number_format }}
      </div> -->
  </div>
</template>

<script>
import QuantityPanel from "@/components/order/QuantityPanel";
import { mapActions } from "vuex";
import CartListItemCardCode from "@/components/cart/CartListItemCardCode";

export default {
  components: { QuantityPanel, CartListItemCardCode },
  props: {
    item: Object,
    view: Boolean,
    success: Boolean,
  },
  methods: {
    ...mapActions({
      fetch: "cart/fetch",
    }),
    async update(quantity) {
      try {
        await this.$axios.patch(`/api/cart/${this.item.id}`, { quantity });
        this.fetch();
      } catch (e) {
        this.handleErrorsMessage(e);
      }
    },
  },
  computed: {
    variations() {
      const variations = this.item.variations;

      return typeof variations == "string" ? variations : variations?.join(", ");
    },
  },
};
</script>
