<template>
  <AppView :title="error.statusCode">
    <div class="h-full flex items-center justify-center">
      
    <div class="text-center">
      <h1 class="text-8xl font-bold">{{ error.statusCode }}</h1>
      <h2 class="text-4xl font-medium" v-if="error.statusCode === 404">找不到網頁</h2>
      <h2 class="text-4xl font-medium" v-else>發生錯誤</h2>
      <NuxtLink to="/" class="text-primary underline font-medium inline-block mt-4"
        >返回首頁</NuxtLink
      >
    </div>
    </div>
  </AppView>
</template>

<script>
export default {
  // layout: "empty", //OR layout:'default'
  props: {
    error: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped></style>
