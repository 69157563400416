import Auth from './auth'

import './middleware'

// Active schemes
import scheme_003d9a64 from './schemes/local.js'
import scheme_23514a38 from './schemes/oauth2.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":false,"watchLoggedIn":true,"redirect":{"login":"/auth/login","logout":"/auth/login","home":"/","callback":"/auth/login"},"vuex":{"namespace":"auth"},"cookie":{"prefix":"auth.","options":{"path":"/","expires":1755849597068,"maxAge":31536000}},"localStorage":{"prefix":"auth."},"token":{"prefix":"_token."},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"local"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new scheme_003d9a64($auth, {"endpoints":{"login":{"url":"/api/login","method":"post","propertyName":"data.token"},"logout":{"url":"/api/logout","method":"post"},"user":{"url":"/api/me","method":"get","propertyName":""},"callback":"/takings"},"token":{"property":"data.token","global":true,"required":true,"type":"Bearer","maxAge":31536000},"_name":"local"}))

  // facebook
  $auth.registerStrategy('facebook', new scheme_23514a38($auth, {"authorization_endpoint":"https://game-853.com/login/facebook","userinfo_endpoint":"https://game-853.com/api/me","_name":"facebook","scope":["public_profile","email"]}))

  // mpay
  $auth.registerStrategy('mpay', new scheme_23514a38($auth, {"authorization_endpoint":"https://game-853.com/login/mpay","userinfo_endpoint":"https://game-853.com/api/me","_name":"mpay"}))

  // wechat
  $auth.registerStrategy('wechat', new scheme_23514a38($auth, {"authorization_endpoint":"https://game-853.com/login/wechat","userinfo_endpoint":"https://game-853.com/api/me","_name":"wechat"}))

  // bocmo
  $auth.registerStrategy('bocmo', new scheme_23514a38($auth, {"authorization_endpoint":"https://game-853.com/login/bocmo","userinfo_endpoint":"https://game-853.com/api/me","_name":"bocmo"}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
