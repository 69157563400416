export default function ({ redirect, $auth, route, i18n }) {

  // 如果沒有登入，就不用檢查
  if (!$auth.loggedIn) {
    return;
  }

  // 獲取語系
  const locale = i18n.locale === i18n.defaultLocale ? "" : "/" + i18n.locale;

  // 獲取用戶資料 
  const user = $auth.user.data;

  if (user?.registered) {
    if (
      user?.status.name === "require_update_password" &&
      !route.name.includes("auth-password")
    ) {
      return redirect(`${locale}/auth/password`);
    }

    if (route.name.includes("auth-binding")) {
      return redirect(`${locale}/order`);
    }
  }else {
    if (
      !route.name.includes("auth-binding")
    ) {
      return redirect(`${locale}/auth/binding`);
    }
  }

  
}
