<template>
  <div class="divide-y">
    <div class="py-3 px-4" v-if="!hasItems">沒有商品</div>

    <CartListItemCard
      v-for="item in items"
      :key="item.id"
      :item="item"
      :view="view"
      :success="isSuccess"
    />

    <template v-if="!bill">
      <div class="px-4 py-2 text-sm">
        <div class="flex items-center justify-end space-x-2 text-right">
          <div>
            <p>總數量</p>
            <p>應付{{ currency }}</p>
            <div class="mt-2" v-if="offer">
              <p>-現金優惠{{ currency }}</p>
              <p>現金應付{{ currency }}</p>
            </div>
          </div>
          <div>
            <p>{{ count }}</p>
            <p>{{ origin_amount | number_format }}</p>
            <div class="mt-2" v-if="offer">
              <p>{{ offer | number_format }}</p>
              <p>{{ amount | number_format }}</p>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="border-b py-3 leading-none" v-else>
      <div class="text-right text-sm text-chicago mb-2">
        小計 {{ currency }}{{ amount | number_format }}
      </div>
      <!-- <span v-if="takeaway">運費 {{currency}}{{fee}}<br></span> -->
      <div class="font-bold text-sm text-primary">
        合計 {{ currency }}<span class="text-lg">{{ total | number_format }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import CartListItemCard from "@/components/cart/CartListItemCard";

export default {
  components: { CartListItemCard },
  props: {
    view: Boolean,
    amount: [Number, String],
    origin_amount: [Number, String],
    offer: [Number, String],
    count: [Number, String],
    items: Array,
    hasItems: Boolean,
    takeaway: Boolean,
    fee: [Number, String],
    bill: Boolean,
    total: [Number, String],
    status: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isSuccess() {
      return this.status.name == "success";
    },
  },
};
</script>
