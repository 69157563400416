import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _64e25483 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _1fc78fb4 = () => interopDefault(import('../pages/bindAccount.vue' /* webpackChunkName: "pages/bindAccount" */))
const _36992310 = () => interopDefault(import('../pages/consent.vue' /* webpackChunkName: "pages/consent" */))
const _f6cba170 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _48498412 = () => interopDefault(import('../pages/me.vue' /* webpackChunkName: "pages/me" */))
const _f070d972 = () => interopDefault(import('../pages/order/index.vue' /* webpackChunkName: "pages/order/index" */))
const _11fb01c9 = () => interopDefault(import('../pages/procedure.vue' /* webpackChunkName: "pages/procedure" */))
const _a3393374 = () => interopDefault(import('../pages/setting.vue' /* webpackChunkName: "pages/setting" */))
const _8de0c6b0 = () => interopDefault(import('../pages/takings/index.vue' /* webpackChunkName: "pages/takings/index" */))
const _5a65b8db = () => interopDefault(import('../pages/wx.vue' /* webpackChunkName: "pages/wx" */))
const _7dc4f218 = () => interopDefault(import('../pages/auth/binding.vue' /* webpackChunkName: "pages/auth/binding" */))
const _e185d540 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _434e7084 = () => interopDefault(import('../pages/auth/help.vue' /* webpackChunkName: "pages/auth/help" */))
const _3b91c3bc = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _6db0bb98 = () => interopDefault(import('../pages/auth/password.vue' /* webpackChunkName: "pages/auth/password" */))
const _33cc28c0 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _1180ebe7 = () => interopDefault(import('../pages/oauth/authorize.vue' /* webpackChunkName: "pages/oauth/authorize" */))
const _1831f2e1 = () => interopDefault(import('../pages/order/checkout.vue' /* webpackChunkName: "pages/order/checkout" */))
const _90d03216 = () => interopDefault(import('../pages/order/confirm.vue' /* webpackChunkName: "pages/order/confirm" */))
const _c5b4ed08 = () => interopDefault(import('../pages/teams/policies/privacy.vue' /* webpackChunkName: "pages/teams/policies/privacy" */))
const _2295f998 = () => interopDefault(import('../pages/teams/policies/refund.vue' /* webpackChunkName: "pages/teams/policies/refund" */))
const _5ece63ae = () => interopDefault(import('../pages/teams/policies/service.vue' /* webpackChunkName: "pages/teams/policies/service" */))
const _2a6d7b2a = () => interopDefault(import('../pages/order/product/_product.vue' /* webpackChunkName: "pages/order/product/_product" */))
const _41aca910 = () => interopDefault(import('../pages/takings/_id.vue' /* webpackChunkName: "pages/takings/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _64e25483,
    name: "about___mo"
  }, {
    path: "/bindAccount",
    component: _1fc78fb4,
    name: "bindAccount___mo"
  }, {
    path: "/consent",
    component: _36992310,
    name: "consent___mo"
  }, {
    path: "/hk",
    component: _f6cba170,
    name: "index___hk"
  }, {
    path: "/me",
    component: _48498412,
    name: "me___mo"
  }, {
    path: "/order",
    component: _f070d972,
    name: "order___mo"
  }, {
    path: "/procedure",
    component: _11fb01c9,
    name: "procedure___mo"
  }, {
    path: "/setting",
    component: _a3393374,
    name: "setting___mo"
  }, {
    path: "/takings",
    component: _8de0c6b0,
    name: "takings___mo"
  }, {
    path: "/wx",
    component: _5a65b8db,
    name: "wx___mo"
  }, {
    path: "/auth/binding",
    component: _7dc4f218,
    name: "auth-binding___mo"
  }, {
    path: "/auth/forgot",
    component: _e185d540,
    name: "auth-forgot___mo"
  }, {
    path: "/auth/help",
    component: _434e7084,
    name: "auth-help___mo"
  }, {
    path: "/auth/login",
    component: _3b91c3bc,
    name: "auth-login___mo"
  }, {
    path: "/auth/password",
    component: _6db0bb98,
    name: "auth-password___mo"
  }, {
    path: "/auth/register",
    component: _33cc28c0,
    name: "auth-register___mo"
  }, {
    path: "/hk/about",
    component: _64e25483,
    name: "about___hk"
  }, {
    path: "/hk/bindAccount",
    component: _1fc78fb4,
    name: "bindAccount___hk"
  }, {
    path: "/hk/consent",
    component: _36992310,
    name: "consent___hk"
  }, {
    path: "/hk/me",
    component: _48498412,
    name: "me___hk"
  }, {
    path: "/hk/order",
    component: _f070d972,
    name: "order___hk"
  }, {
    path: "/hk/procedure",
    component: _11fb01c9,
    name: "procedure___hk"
  }, {
    path: "/hk/setting",
    component: _a3393374,
    name: "setting___hk"
  }, {
    path: "/hk/takings",
    component: _8de0c6b0,
    name: "takings___hk"
  }, {
    path: "/hk/wx",
    component: _5a65b8db,
    name: "wx___hk"
  }, {
    path: "/oauth/authorize",
    component: _1180ebe7,
    name: "oauth-authorize___mo"
  }, {
    path: "/order/checkout",
    component: _1831f2e1,
    name: "order-checkout___mo"
  }, {
    path: "/order/confirm",
    component: _90d03216,
    name: "order-confirm___mo"
  }, {
    path: "/hk/auth/binding",
    component: _7dc4f218,
    name: "auth-binding___hk"
  }, {
    path: "/hk/auth/forgot",
    component: _e185d540,
    name: "auth-forgot___hk"
  }, {
    path: "/hk/auth/help",
    component: _434e7084,
    name: "auth-help___hk"
  }, {
    path: "/hk/auth/login",
    component: _3b91c3bc,
    name: "auth-login___hk"
  }, {
    path: "/hk/auth/password",
    component: _6db0bb98,
    name: "auth-password___hk"
  }, {
    path: "/hk/auth/register",
    component: _33cc28c0,
    name: "auth-register___hk"
  }, {
    path: "/hk/oauth/authorize",
    component: _1180ebe7,
    name: "oauth-authorize___hk"
  }, {
    path: "/hk/order/checkout",
    component: _1831f2e1,
    name: "order-checkout___hk"
  }, {
    path: "/hk/order/confirm",
    component: _90d03216,
    name: "order-confirm___hk"
  }, {
    path: "/teams/policies/privacy",
    component: _c5b4ed08,
    name: "teams-policies-privacy___mo"
  }, {
    path: "/teams/policies/refund",
    component: _2295f998,
    name: "teams-policies-refund___mo"
  }, {
    path: "/teams/policies/service",
    component: _5ece63ae,
    name: "teams-policies-service___mo"
  }, {
    path: "/hk/teams/policies/privacy",
    component: _c5b4ed08,
    name: "teams-policies-privacy___hk"
  }, {
    path: "/hk/teams/policies/refund",
    component: _2295f998,
    name: "teams-policies-refund___hk"
  }, {
    path: "/hk/teams/policies/service",
    component: _5ece63ae,
    name: "teams-policies-service___hk"
  }, {
    path: "/",
    component: _f6cba170,
    name: "index___mo"
  }, {
    path: "/hk/order/product/:product?",
    component: _2a6d7b2a,
    name: "order-product-product___hk"
  }, {
    path: "/hk/takings/:id",
    component: _41aca910,
    name: "takings-id___hk"
  }, {
    path: "/order/product/:product?",
    component: _2a6d7b2a,
    name: "order-product-product___mo"
  }, {
    path: "/takings/:id",
    component: _41aca910,
    name: "takings-id___mo"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
