<template>
  <div class="grid grid-cols-3 gap-x-4">
    <FieldWithLabel
      class="col-span-2"
      :id="$attrs.id"
      :label="$attrs.label"
      :type="$attrs.type"
      :options="$attrs.options"
      @input="$emit('input', $event)"
      :disabled="disabled"
      :placeholder="$attrs.placeholder"
    />

    <div>
      <button
        :class="[{ 'mt-5': $attrs.label }, 'btn px-4 text-base w-full']"
        type="button"
        :disabled="disabled || count > 0 || submitting"
        @click="validation"
      >
        {{ text }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    authtype: {
      type: String,
      default: "register",
    },
    tel: {
      type: [String, Number],
      required: true,
    },
    code: {
      type: String,
      default: "+853",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      count: 0,
      countdown: null,
      submitting: false,
    };
  },

  methods: {
    counting() {
      this.count = 180;
      this.countdown = setInterval(() => {
        this.count -= 1;

        if (this.count <= 0) clearInterval(this.countdown);
      }, 1000);
    },
    async validation() {
      if (this.count || this.submitting) return;
      this.$loader.load();
      try {
        this.submitting = true;
        await this.$axios.post("/api/verify/tel", {
          type: this.authtype,
          tel: this.tel,
          country_code: this.code,
        });

        this.counting();
      } catch (e) {
        console.error(e);
      } finally {
        this.submitting = false;
        this.$loader.close();
      }
    },
  },
  computed: {
    text() {
      return this.count ? this.count : "認證碼";
    },
  },
};
</script>

<style lang="scss" scoped></style>
