<template>
  <div class="relative h-app">
    <div class="flex flex-col h-full max-w-screen-sm mx-auto w-full">
      <TitleBarCard :title="title" @close="close" />

      <div class="flex-grow bg-white overflow-auto p-5 scrollbar-hide">
        <img class="w-full h-auto" :src="image" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import TitleBarCard from "@/components/card/TitleBarCard.vue";
export default {
  components: {TitleBarCard},
  props: {
    type: {
      type: String,
    },
  },
  methods: {
    close() {
      this.$parent.close();
    },
  },
  computed: {
   

    title() {
      console.log(this.type);
      
      if (this.type == "bocpay") return "中銀BOC支付的認證教學";
      if (this.type == "mpay") return "MPay支付的認證教學";
      if (this.type == "payme") return "PayMe支付的認證教學";
      return "發送短訊教學";
    },

    image() {
      if (this.type == "bocpay") return "/imgs/legend/bocpay.jpg";
      if (this.type == "mpay") return "/imgs/legend/mpay.jpg";
      if (this.type == "payme") return "/imgs/legend/payme.jpg";
      return "/imgs/sms.jpg";
    },
  },
};
</script>

<style lang="scss" scoped></style>
